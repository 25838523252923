import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7d1c1fce = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _1a1e26a8 = () => interopDefault(import('../pages/contact/index.vue' /* webpackChunkName: "pages/contact/index" */))
const _38a0d0ba = () => interopDefault(import('../pages/garuda-id/index.vue' /* webpackChunkName: "pages/garuda-id/index" */))
const _7ec8440b = () => interopDefault(import('../pages/live-streaming/index.vue' /* webpackChunkName: "pages/live-streaming/index" */))
const _6f4ace16 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2d3325e6 = () => interopDefault(import('../pages/matchcenter/index.vue' /* webpackChunkName: "pages/matchcenter/index" */))
const _648b5f40 = () => interopDefault(import('../pages/membership/index.vue' /* webpackChunkName: "pages/membership/index" */))
const _6ab463e6 = () => interopDefault(import('../pages/myaccount/index.vue' /* webpackChunkName: "pages/myaccount/index" */))
const _0476cb46 = () => interopDefault(import('../pages/news/index.vue' /* webpackChunkName: "pages/news/index" */))
const _6056c07e = () => interopDefault(import('../pages/photo/index.vue' /* webpackChunkName: "pages/photo/index" */))
const _340dbee1 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _4df2f02e = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _7ebedea2 = () => interopDefault(import('../pages/signup/index.vue' /* webpackChunkName: "pages/signup/index" */))
const _f3939758 = () => interopDefault(import('../pages/team-squad/index.vue' /* webpackChunkName: "pages/team-squad/index" */))
const _40f19a9a = () => interopDefault(import('../pages/tickets/index.vue' /* webpackChunkName: "pages/tickets/index" */))
const _a877ce3c = () => interopDefault(import('../pages/videos/index.vue' /* webpackChunkName: "pages/videos/index" */))
const _5bc6b96e = () => interopDefault(import('../pages/welcome/index.vue' /* webpackChunkName: "pages/welcome/index" */))
const _312cc8f6 = () => interopDefault(import('../pages/admin/account.vue' /* webpackChunkName: "pages/admin/account" */))
const _1f64be79 = () => interopDefault(import('../pages/admin/ads.vue' /* webpackChunkName: "pages/admin/ads" */))
const _b98e2616 = () => interopDefault(import('../pages/admin/fanforum.vue' /* webpackChunkName: "pages/admin/fanforum" */))
const _c42250b4 = () => interopDefault(import('../pages/admin/fangallery.vue' /* webpackChunkName: "pages/admin/fangallery" */))
const _28a1e67d = () => interopDefault(import('../pages/admin/fanprofile.vue' /* webpackChunkName: "pages/admin/fanprofile" */))
const _3653569d = () => interopDefault(import('../pages/admin/fanvotes.vue' /* webpackChunkName: "pages/admin/fanvotes" */))
const _6f1359f0 = () => interopDefault(import('../pages/admin/fanzone.vue' /* webpackChunkName: "pages/admin/fanzone" */))
const _3b50ffca = () => interopDefault(import('../pages/admin/games.vue' /* webpackChunkName: "pages/admin/games" */))
const _3a2dca34 = () => interopDefault(import('../pages/admin/home.vue' /* webpackChunkName: "pages/admin/home" */))
const _cfc1af1c = () => interopDefault(import('../pages/admin/login.vue' /* webpackChunkName: "pages/admin/login" */))
const _7e2df36d = () => interopDefault(import('../pages/admin/media.vue' /* webpackChunkName: "pages/admin/media" */))
const _530796da = () => interopDefault(import('../pages/admin/news.vue' /* webpackChunkName: "pages/admin/news" */))
const _fbc30ee4 = () => interopDefault(import('../pages/admin/newsreport.vue' /* webpackChunkName: "pages/admin/newsreport" */))
const _8d49e470 = () => interopDefault(import('../pages/admin/photos.vue' /* webpackChunkName: "pages/admin/photos" */))
const _411543e8 = () => interopDefault(import('../pages/admin/prizes.vue' /* webpackChunkName: "pages/admin/prizes" */))
const _18f9c52a = () => interopDefault(import('../pages/admin/products.vue' /* webpackChunkName: "pages/admin/products" */))
const _13665889 = () => interopDefault(import('../pages/admin/teamsquad/index.vue' /* webpackChunkName: "pages/admin/teamsquad/index" */))
const _142548b0 = () => interopDefault(import('../pages/admin/tickets.vue' /* webpackChunkName: "pages/admin/tickets" */))
const _6d9feb4e = () => interopDefault(import('../pages/admin/topskor.vue' /* webpackChunkName: "pages/admin/topskor" */))
const _772dbd1f = () => interopDefault(import('../pages/admin/videos.vue' /* webpackChunkName: "pages/admin/videos" */))
const _3ec3d1da = () => interopDefault(import('../pages/admin/videosreport.vue' /* webpackChunkName: "pages/admin/videosreport" */))
const _ac8ca64a = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _4488ed2f = () => interopDefault(import('../pages/admin/teamsquad/official/_id.vue' /* webpackChunkName: "pages/admin/teamsquad/official/_id" */))
const _05563936 = () => interopDefault(import('../pages/admin/teamsquad/player/_id.vue' /* webpackChunkName: "pages/admin/teamsquad/player/_id" */))
const _11afb741 = () => interopDefault(import('../pages/admin/teamsquad/_slug.vue' /* webpackChunkName: "pages/admin/teamsquad/_slug" */))
const _6efa691c = () => interopDefault(import('../pages/fansubmission/_type.vue' /* webpackChunkName: "pages/fansubmission/_type" */))
const _59f871af = () => interopDefault(import('../pages/football/_slug.vue' /* webpackChunkName: "pages/football/_slug" */))
const _f3c91d4a = () => interopDefault(import('../pages/games/_category/index.vue' /* webpackChunkName: "pages/games/_category/index" */))
const _07e40dd6 = () => interopDefault(import('../pages/news/_slug.vue' /* webpackChunkName: "pages/news/_slug" */))
const _5ea01f36 = () => interopDefault(import('../pages/photo/_slug.vue' /* webpackChunkName: "pages/photo/_slug" */))
const _df34f714 = () => interopDefault(import('../pages/prizes/_category.vue' /* webpackChunkName: "pages/prizes/_category" */))
const _abe510cc = () => interopDefault(import('../pages/videos/_slug.vue' /* webpackChunkName: "pages/videos/_slug" */))
const _377e88c1 = () => interopDefault(import('../pages/fanvote/_view/_action.vue' /* webpackChunkName: "pages/fanvote/_view/_action" */))
const _630de038 = () => interopDefault(import('../pages/fanzone/_view/_slug.vue' /* webpackChunkName: "pages/fanzone/_view/_slug" */))
const _ee4d91b8 = () => interopDefault(import('../pages/football/_view/_slug.vue' /* webpackChunkName: "pages/football/_view/_slug" */))
const _0b24d21e = () => interopDefault(import('../pages/games/_category/_action.vue' /* webpackChunkName: "pages/games/_category/_action" */))
const _552fbed8 = () => interopDefault(import('../pages/news/_league/_slug.vue' /* webpackChunkName: "pages/news/_league/_slug" */))
const _46a00cb4 = () => interopDefault(import('../pages/products/_category/_slug.vue' /* webpackChunkName: "pages/products/_category/_slug" */))
const _404ff97f = () => interopDefault(import('../pages/team-squad/_group/_category/index.vue' /* webpackChunkName: "pages/team-squad/_group/_category/index" */))
const _a38760b2 = () => interopDefault(import('../pages/team-squad/_group/_category/_id.vue' /* webpackChunkName: "pages/team-squad/_group/_category/_id" */))
const _a09bd4f2 = () => interopDefault(import('../pages/matchdetail/_leagueid/_matchid/_staticid/_comm/_date/_today.vue' /* webpackChunkName: "pages/matchdetail/_leagueid/_matchid/_staticid/_comm/_date/_today" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7d1c1fce,
    name: "about"
  }, {
    path: "/contact",
    component: _1a1e26a8,
    name: "contact"
  }, {
    path: "/garuda-id",
    component: _38a0d0ba,
    name: "garuda-id"
  }, {
    path: "/live-streaming",
    component: _7ec8440b,
    name: "live-streaming"
  }, {
    path: "/login",
    component: _6f4ace16,
    name: "login"
  }, {
    path: "/matchcenter",
    component: _2d3325e6,
    name: "matchcenter"
  }, {
    path: "/membership",
    component: _648b5f40,
    name: "membership"
  }, {
    path: "/myaccount",
    component: _6ab463e6,
    name: "myaccount"
  }, {
    path: "/news",
    component: _0476cb46,
    name: "news"
  }, {
    path: "/photo",
    component: _6056c07e,
    name: "photo"
  }, {
    path: "/privacy-policy",
    component: _340dbee1,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _4df2f02e,
    name: "products"
  }, {
    path: "/signup",
    component: _7ebedea2,
    name: "signup"
  }, {
    path: "/team-squad",
    component: _f3939758,
    name: "team-squad"
  }, {
    path: "/tickets",
    component: _40f19a9a,
    name: "tickets"
  }, {
    path: "/videos",
    component: _a877ce3c,
    name: "videos"
  }, {
    path: "/welcome",
    component: _5bc6b96e,
    name: "welcome"
  }, {
    path: "/admin/account",
    component: _312cc8f6,
    name: "admin-account"
  }, {
    path: "/admin/ads",
    component: _1f64be79,
    name: "admin-ads"
  }, {
    path: "/admin/fanforum",
    component: _b98e2616,
    name: "admin-fanforum"
  }, {
    path: "/admin/fangallery",
    component: _c42250b4,
    name: "admin-fangallery"
  }, {
    path: "/admin/fanprofile",
    component: _28a1e67d,
    name: "admin-fanprofile"
  }, {
    path: "/admin/fanvotes",
    component: _3653569d,
    name: "admin-fanvotes"
  }, {
    path: "/admin/fanzone",
    component: _6f1359f0,
    name: "admin-fanzone"
  }, {
    path: "/admin/games",
    component: _3b50ffca,
    name: "admin-games"
  }, {
    path: "/admin/home",
    component: _3a2dca34,
    name: "admin-home"
  }, {
    path: "/admin/login",
    component: _cfc1af1c,
    name: "admin-login"
  }, {
    path: "/admin/media",
    component: _7e2df36d,
    name: "admin-media"
  }, {
    path: "/admin/news",
    component: _530796da,
    name: "admin-news"
  }, {
    path: "/admin/newsreport",
    component: _fbc30ee4,
    name: "admin-newsreport"
  }, {
    path: "/admin/photos",
    component: _8d49e470,
    name: "admin-photos"
  }, {
    path: "/admin/prizes",
    component: _411543e8,
    name: "admin-prizes"
  }, {
    path: "/admin/products",
    component: _18f9c52a,
    name: "admin-products"
  }, {
    path: "/admin/teamsquad",
    component: _13665889,
    name: "admin-teamsquad"
  }, {
    path: "/admin/tickets",
    component: _142548b0,
    name: "admin-tickets"
  }, {
    path: "/admin/topskor",
    component: _6d9feb4e,
    name: "admin-topskor"
  }, {
    path: "/admin/videos",
    component: _772dbd1f,
    name: "admin-videos"
  }, {
    path: "/admin/videosreport",
    component: _3ec3d1da,
    name: "admin-videosreport"
  }, {
    path: "/",
    component: _ac8ca64a,
    name: "index"
  }, {
    path: "/admin/teamsquad/official/:id?",
    component: _4488ed2f,
    name: "admin-teamsquad-official-id"
  }, {
    path: "/admin/teamsquad/player/:id?",
    component: _05563936,
    name: "admin-teamsquad-player-id"
  }, {
    path: "/admin/teamsquad/:slug",
    component: _11afb741,
    name: "admin-teamsquad-slug"
  }, {
    path: "/fansubmission/:type?",
    component: _6efa691c,
    name: "fansubmission-type"
  }, {
    path: "/football/:slug?",
    component: _59f871af,
    name: "football-slug"
  }, {
    path: "/games/:category",
    component: _f3c91d4a,
    name: "games-category"
  }, {
    path: "/news/:slug",
    component: _07e40dd6,
    name: "news-slug"
  }, {
    path: "/photo/:slug",
    component: _5ea01f36,
    name: "photo-slug"
  }, {
    path: "/prizes/:category?",
    component: _df34f714,
    name: "prizes-category"
  }, {
    path: "/videos/:slug",
    component: _abe510cc,
    name: "videos-slug"
  }, {
    path: "/fanvote/:view?/:action?",
    component: _377e88c1,
    name: "fanvote-view-action"
  }, {
    path: "/fanzone/:view?/:slug?",
    component: _630de038,
    name: "fanzone-view-slug"
  }, {
    path: "/football/:view?/:slug?",
    component: _ee4d91b8,
    name: "football-view-slug"
  }, {
    path: "/games/:category?/:action",
    component: _0b24d21e,
    name: "games-category-action"
  }, {
    path: "/news/:league/:slug?",
    component: _552fbed8,
    name: "news-league-slug"
  }, {
    path: "/products/:category/:slug?",
    component: _46a00cb4,
    name: "products-category-slug"
  }, {
    path: "/team-squad/:group?/:category",
    component: _404ff97f,
    name: "team-squad-group-category"
  }, {
    path: "/team-squad/:group?/:category/:id?",
    component: _a38760b2,
    name: "team-squad-group-category-id"
  }, {
    path: "/matchdetail/:leagueid?/:matchid?/:staticid?/:comm?/:date?/:today?",
    component: _a09bd4f2,
    name: "matchdetail-leagueid-matchid-staticid-comm-date-today"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
